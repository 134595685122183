@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Aldrich|Anonymous+Pro:400,700,700i|Libre+Baskerville|Oxygen+Mono&display=swap');
.backbody{
    background-color: black;
    color: white;
    width: 100vw;
    height: 100vh;
}

p {
    width: 90%; 
    overflow-wrap: break-word;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 200;
    text-align: justify;
    /* font-family: 'Times New Roman', Times, serif */
    letter-spacing: 2px;
    
}

.main {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    /* height: 90vh; */
}

.typewriter p {
    /* overflow: hidden; Ensures the content is not revealed until the animation */
    /* border-right: .15em solid orange; The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    /* letter-spacing: .15em; Adjust as needed */
    letter-spacing: 13px;
    /* overflow-wrap: break-word; */
    font-family: Arial, Helvetica, sans-serif;
    /* font-weight: bold; */
    /* animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite; */
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }


  /* Typist {
    letter-spacing: 8px;
  } */

/* .text-pop-up-top {
    width: 90%; 
    overflow-wrap: break-word;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 200;
	-webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.150, 0.460, 0.450, 0.240) both;
	        animation: text-pop-up-top 0.5s cubic-bezier(0.150, 0.460, 0.450, 0.240) both;
}



/* ----------------------------------------------
 * Generated by Animista on 2020-2-8 21:46:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-pop-up-top
 * ----------------------------------------
 */
 /* @-webkit-keyframes text-pop-up-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      text-shadow: none;
    }
    100% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
  }
  @keyframes text-pop-up-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      text-shadow: none;
    }
    100% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
  } */
   

  .pBottom{
    padding-bottom: 140px;
  }

  .writings{
      line-height: 1;
      font-size: larger;
      font-family: 'Oxygen Mono', monospace;
  }


/* 

font-family: 'Aldrich', sans-serif;
font-family: 'Libre Baskerville', serif;
font-family: 'Abril Fatface', cursive;
font-family: 'Anonymous Pro', monospace;
font-family: 'Oxygen Mono', monospace;

 */


